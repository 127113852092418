body {
    margin-top: 25px;
}
.container {
    max-width: 700px;
}
.score-box {
    text-align: center;
    padding-top: 10px;
    /* padding-bottom: 50px; */
    height: 150px;
    margin-top: 5px;
    margin-bottom: 30px;
    background-color:rgb(240, 240, 240);
    border-radius: 10px;
}
.score-header {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: .2rem;
    font-weight: 600;
}
.score-value {
    font-size: 3rem;
    font-weight:400;
}
.settings-box {
    background-color:rgb(240, 240, 240);
    border-radius: 10px;
    text-align: center;
}
.guess-box {
    border:none;
    background-color: rgb(240, 240, 240);
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.play-button {
    width:100%;
    margin-right:5px;
}
@keyframes greenfade {
    from {
        background: lightgreen;
    }
    to {
        background: rgb(240, 240, 240);;
    }
}
@keyframes redfade {
    from {
        background: lightcoral;
    }
    to {
        background: rgb(240, 240, 240);;
    }
}
.score-highlight-green {
    animation: greenfade 1s;
}
.score-highlight-red {
    animation: redfade 1s;
}
  